<template>
  <DetailTemplate
    :customClass="'lead-detail detail-page'"
    v-if="getPermission('lead:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="100"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            {{ leadTitle }}
          </h1>
          <CustomStatus
            :status.sync="leadDetail.status"
            endpoint="leads/status"
          ></CustomStatus>
          <template v-if="leadDetail.lost">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 custom-status font-weight-600 custom-grey-border text-uppercase justify-center"
                  color="orange darken-4"
                  text-color="white"
                  label
                >
                  Lost
                </v-chip>
              </template>
              <span>Lost Lead</span>
            </v-tooltip>
          </template>
          <template v-if="leadDetail.junk">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 custom-status font-weight-600 custom-grey-border text-uppercase justify-center"
                  color="red lighted-1"
                  text-color="white"
                  label
                >
                  Junk
                </v-chip>
              </template>
              <span>Junk Lead</span>
            </v-tooltip>
          </template>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="updateMoreAction('edit')"
      >
        Edit <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="red lighted-1"
        v-on:click="updateMoreAction('delete')"
      >
        Delete <v-icon small>mdi-delete</v-icon>
      </v-btn>
      <v-menu
        transition="slide-y-transition"
        bottom
        content-class="custom-menu-list"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            More... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(more, index) in moreActions">
            <v-list-item
              link
              v-on:click="updateMoreAction(more.action)"
              :key="index"
              :disabled="more.disabled"
            >
              <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-500 font-size-14">{{
                more.title
              }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid>
        <v-row>
          <v-col md="6">
            <table width="100%">
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Lead Title
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ leadDetail.title }}
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Customer Name
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leadDetail.name">{{
                    leadDetail.name
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Name</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Customer Company
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leadDetail.company">{{
                    leadDetail.company
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Company </em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Customer Email
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-lowercase">
                  <template v-if="leadDetail.email">{{
                    leadDetail.email
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Email </em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Customer Phone No.
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leadDetail.phone_number">{{
                    leadDetail.phone_number
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Phone Number</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Customer Website
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-lowercase">
                  <template v-if="leadDetail.website">{{
                    leadDetail.website
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Website</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Lead Date
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leadDetail.lead_date">{{
                    formatDate(leadDetail.lead_date)
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Lead Date</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Last Contacted Date
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leadDetail.last_contact">{{
                    formatDate(leadDetail.last_contact)
                  }}</template>
                  <template v-else
                    ><em class="text--secondary"
                      >No Last Contacted Date</em
                    ></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Status
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leadDetail.status_text">{{
                    leadDetail.status_text
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Status</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Source
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leadDetail.source">{{
                    leadDetail.source.text
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Source</em></template
                  >
                </td>
              </tr>
            </table>
          </v-col>
          <v-col md="6">
            <table width="100%">
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Lead #
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  {{ leadDetail.barcode }}
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Reference #
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leadDetail.reference">{{
                    leadDetail.reference
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Reference #</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Address
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leadDetail.address">{{
                    leadDetail.address
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Address</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Country
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leadDetail.country">{{
                    leadDetail.country
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Country</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  State
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leadDetail.state">{{
                    leadDetail.state
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No State</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  City
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leadDetail.city">{{
                    leadDetail.city
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No City</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Postal Code
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leadDetail.zip">{{
                    leadDetail.zip
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Postal Code</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Extra
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leadDetail.extra">{{
                    leadDetail.extra.text
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Extra</em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Lead Value
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leadDetail.lead_value">{{
                    $accountingJS.formatMoney(leadDetail.lead_value)
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No Lead Value </em></template
                  >
                </td>
              </tr>
              <tr>
                <td width="200" class="pb-2 font-size-18 text-capitalize">
                  Assigned
                </td>
                <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                  <template v-if="leadDetail.assigned_user">{{
                    leadDetail.assigned_user.full_name
                  }}</template>
                  <template v-else
                    ><em class="text--secondary">No User Assigned</em></template
                  >
                </td>
              </tr>
            </table>
          </v-col>
          <v-col
            md="12"
            class="py-0"
            content="Description"
            v-tippy="{ placement: 'top-start' }"
          >
            <read-more
              class="custom-read-more custom-border-grey-dashed font-weight-600 font-size-18 p-4 custom-gray-color"
              more-str="read more"
              :text="leadDetail.description || '<em>No Description</em>'"
              link="#"
              less-str="read less"
              :max-chars="200"
            >
            </read-more>
          </v-col>
          <v-col md="12">
            <FileTemplate
              :allowDelete="false"
              :attachments.sync="attachments"
            ></FileTemplate>
          </v-col>
        </v-row>
      </v-container>
      <LeadDelete
        :deleteDialog.sync="leadDeleteDialog"
        :requestUrl.sync="leadDeleteRequestUrl"
        v-on:delete:success="leadDeleteSuccess"
        v-on:delete:close="leadDeleteDialog = false"
      ></LeadDelete>
    </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH } from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import LeadDelete from "@/view/pages/partials/Delete-Request-Template.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "lead-detail",
  title: "Detail Lead",
  data() {
    return {
      lead: null,
      leadDeleteDialog: false,
      moreActions: new Array(),
      attachments: new Array(),
      pageLoading: false,
      leadDetail: new Object({
        reference: null,
        assigned_user: null,
        name: null,
        company: null,
        email: null,
        website: null,
        phone_number: null,
        country: null,
        zip: null,
        city: null,
        state: null,
        address: null,
        title: null,
        description: null,
        status: null,
        status_text: null,
        source: null,
        extra: null,
        lost: null,
        junk: null,
        lead_value: null,
        lead_date: null,
        last_contact: null,
        attachments: new Array(),
      }),
    };
  },
  components: {
    DetailTemplate,
    CustomStatus,
    LeadDelete,
    FileTemplate,
  },
  methods: {
    leadDeleteSuccess() {
      this.leadDeleteDialog = false;
      this.goBack();
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "edit":
          _this.$router.push(
            _this.getDefaultRoute("lead.update", {
              params: {
                id: _this.lead,
              },
            })
          );
          break;
        case "convert_to_customer":
          _this.$router.push(
            _this.getDefaultRoute("customer.create", {
              query: {
                lead: _this.lead,
              },
            })
          );
          break;
        case "convert_to_proposal":
          _this.$router.push(
            _this.getDefaultRoute("proposal.create", {
              query: {
                lead: _this.lead,
              },
            })
          );
          break;
        case "delete":
          _this.leadDeleteDialog = true;
          break;
        case "mark_as_lost":
          _this.updateLead({ lost: 1 });
          break;
        case "mark_as_junk":
          _this.updateLead({ junk: 1 });
          break;
        case "mark_as_follow_up":
          _this.updateLead({ status: 2 });
          break;
        case "mark_as_1st_meeting":
          _this.updateLead({ status: 3 });
          break;
        case "mark_as_2nd_meeting":
          _this.updateLead({ status: 6 });
          break;
        case "mark_as_proposal":
          _this.updateLead({ status: 4 });
          break;
        case "mark_as_quotation":
          _this.updateLead({ status: 5 });
          break;
        case "mark_as_kw_send_out":
          _this.updateLead({ status: 7 });
          break;
        case "mark_as_reject":
          _this.updateLead({ status: 8 });
          break;
        case "mark_as_accept":
          _this.updateLead({ status: 9 });
          break;
        case "mark_as_resend_quote":
          _this.updateLead({ status: 10 });
          break;
        case "mark_as_on_hold":
          _this.updateLead({ status: 11 });
          break;
        case "mark_as_waiting_for_scope":
          _this.updateLead({ status: 12 });
      }
    },
    updateLead(data) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "leads/" + _this.lead,
          data: data,
        })
        .then(() => {
          _this.getLead();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getLead() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leads/" + _this.lead,
        })
        .then(({ data }) => {
          _this.leadDetail = new Object({
            barcode: data.barcode || null,
            reference: data.reference || null,
            assigned_user: data.assigned_user || null,
            name: data.name || null,
            company: data.company || null,
            email: data.email || null,
            website: data.website || null,
            phone_number: data.phone_number || null,
            country: data.country || null,
            zip: data.zip || null,
            city: data.city || null,
            state: data.state || null,
            address: data.address || null,
            title: data.title || null,
            description: data.description || null,
            status_text: data.status_text || null,
            status: data.status || null,
            source: data.source || null,
            extra: data.extra || null,
            lost: data.lost,
            junk: data.junk,
            lead_value: data.lead_value || null,
            lead_date: data.lead_date || null,
            last_contact: data.last_contact || null,
            attachments: new Array(),
          });
          _this.attachments = data.attachments;
          _this.moreActions = data.more_actions;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Lead", route: "lead" },
      { title: "Detail" },
    ]);

    _this.lead = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.lead <= 0) {
      _this.goBack();
    }

    _this.getLead();
  },
  computed: {
    leadDeleteRequestUrl() {
      return "leads/" + this.lead;
    },
    leadTitle() {
      return this.leadDetail.title;
    },
  },
};
</script>
